<template>
   <div class="statistics-detail-wrapper">
      <div class="flex-box-column statistics-detail-cover">
         <div class="hd">
            <van-nav-bar left-arrow safe-area-inset-top :title="houseForm.staffName+' 楼盘数据详情'" class="search" @click-left="back">
            </van-nav-bar>
         </div>
         <div class="bd flex-box-column">
            <!-- 区间总数据 -->
            <div class="bg statisics-section statisics-title">
               <div class="statisics-row">
                  <div class="statisics-icon"></div>
                  <div class="statisics-item">
                     <div class="statisics-item_content name">
                        <p>楼盘名称</p>
                     </div>  
                     <div class="statisics-item_content">
                        <p>报备量</p>
                        <p>{{statiscsAllData['baobeiNum'] || 0}}</p>
                     </div>
                     <div class="statisics-item_content">
                        <p>到访量</p>
                        <p>{{statiscsAllData['visit'] || 0}}</p>
                     </div>
                     <div class="statisics-item_content">
                        <p>成交量</p>
                        <p>{{statiscsAllData['makeBargain'] || 0}}</p>
                     </div>
                     <div class="statisics-item_content long">
                        <p>成交金额(万)</p>
                        <p>{{statiscsAllData['sumMoney'] || '0.00'}}</p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="statisics-section f-scroll-view">
                   <template  v-for="(item,index) in statiscsDetailData">
                     <div class="statisics-row" v-if="statiscsDetailData" :key="index" @click="openHouseData(item)">
                        <div class="statisics-icon rank">
                           <span v-if="index<3" :class="'rank_'+(index+1)"></span>
                        </div>
                        <div class="statisics-item" :class="{' van-hairline--top':index>0}">
                           <div class="statisics-item_content name">
                              <p>{{item['name']}}</p>
                           </div>  
                           <div class="statisics-item_content">
                              <p>{{item['baobeiNum'] || 0}}</p>
                           </div>
                           <div class="statisics-item_content">
                              <p>{{item['visit'] || 0}}</p>
                           </div>
                           <div class="statisics-item_content">
                              <p>{{item['makeBargain'] || 0}}</p>
                           </div>
                           <div class="statisics-item_content long">
                              <p>{{item['sumMoney'] || '0.00'}}</p>
                           </div>
                        </div>
                  </div>
                  </template>
                  <div class="fky-tips" v-if="(statiscsDetailData || statiscsDetailData.length == 0) && inited">暂无数据</div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
/**
 * 统计页面
 */
import backMixin from "@/mixins/back";
import api from "@/api";
import sessionStorage from "@/utils/sessionStorage";
export default {

   mixins:[backMixin],

   data(){

      return {

         //是否初始化完毕

         inited:false,



         statiscsAllData:{},

         statiscsDetailData:[],

         houseForm:{},


      }


   },

   computed:{

      //id

      statisticsId(){

         return this.$route.params.id;

      }

   },


   methods:{



      //请求对应拓展口的楼盘数据

      updateData(){

         this.$toast.loading({

            message:'正在加载...',

            duration:0,

            forbidClick:true
            
         });

         let options = Object.assign(this.houseForm,{id:this.statisticsId});
         if(options.startDate && options.endDate){
            options.searchDate = [options.startDate,options.endDate];
         }

         api.getExtensionHouseStatistics({

            options,

            success:(res)=>{

               if(res.type == 200){

                  if(res.data?.all && res.data?.details){

                     this.statiscsAllData = res.data?.all || {};
                     
                     this.statiscsDetailData = res.data?.details || [];

                  }


                  this.$nextTick(()=>{

                     this.houseDataShow=true;

                  })

               }else{

                  this.$toast.fail(res.msg || '获取数据失败，数据异常，请稍后重试');

               }

               this.inited=true;

            },

            error:(err)=>{

               console.log(err);

               this.$toast.fail('获取数据失败，数据异常，请稍后重试');

               this.inited=true;
    

            },

            complete:()=>{

               this.$toast.clear();



            }


         })

      },


      

      //初始化方法

      init(){

        this.updateData();

      },

   },
   created() {

      this.houseForm = sessionStorage.StorageGetter('expand_house_data') || {};
          
      this.$nextTick(()=>{

         this.init();

      })

   },

    
    beforeDestroy(){

        //销毁缓存

         sessionStorage.StorageRemove('expand_house_data')

   },




}
</script>

<style lang="less">
.statistics-detail-wrapper{

   height:100%;

   .search-house-cover{

      padding: 5px 10px;

      .van-cell{

         line-height:32px;

      }

   }

   .house-data-show-wrapper{

      background: #fff;

      width: 90%;

      position: absolute;

      left:50%;

      top:50%;

      transform: translate(-50%,-50%);

      height:80vh;

      overflow: hidden;

      border-radius:5px;

      .house-data-show-cover{

         width: 100%;

         height:100%;

      }

      .house-data-show-title{

         font-size:16px;

         padding: 10px 16px;

         line-height:normal;

         display: flex;
         
         justify-content: space-between;

         align-items: center;

         .house-data-show-close{

            padding-left:10px;

            &:active{

               color:#00a0e4;

            }

         }

         .van-icon{

            font-size:18px;

         }

      }

      .statisics-section .statisics-row .statisics-item .statisics-item_content{

         padding: 10px 0;

      }

      .statisics-section.statisics-title .statisics-row .statisics-item .statisics-item_content p{

         font-size:11px;

         &:nth-child(2){

            font-size:13px;

         }

      }

      .statisics-section .statisics-row .statisics-item .statisics-item_content p{

         font-size:13px;

      }

      .statisics-section .statisics-row .statisics-icon{

         padding: 10px 0;

      }

   }

   .search-button-cover{

      padding: 5px 10px 10px;

   }

   .search-dialog-input-cover{

      padding: 10px 0;

   }

   .statistics-detail-cover{

      height:100%;


   }

   .hd{

      position: relative;

      z-index:30;

         .date-interval{

               display: flex;

               padding:15px;

               align-items: center;

               .date-interval-item{

                   font-size:13px;

                   line-height:24px;

                   padding: 2px 8px 1px;

                   color:#999;

                   background:#f6f6f6;

                   &.confirm{

                    margin-left:40px;

                    padding: 2px 15px 1px;

                    background: #eee;

                    border-radius:3px;

                    border: 0;

                    &.live{

                        background: #00a0e4;

                        color:#fff;

                    }

                   }

               }

           }

   }

         .statisics-section{

         &.statisics-title{

            background: #f9f9f9;

            color:#999999;

            .statisics-row .statisics-item .statisics-item_content p{

               font-size:13px;

            }

         }

         .statisics-row{

            display: flex;

            padding-right:10px;

            .statisics-icon{

               flex:0.9;

               text-align: center;

               display: flex;

               padding:15px 0;

               justify-content: center;

               align-items: center;

               &.rank span{

                  background:url('../../assets/images/rank.png') no-repeat center center;

                  background-size:60px 18px;

                  display: block;

                  width: 18px;

                  height:18px;

                  position: relative;

                  top:-1px;

                  &.rank_1{

                     background-position: 0 0;

                  }

                  &.rank_2{

                     background-position: -21px 0;

                  }

                  &.rank_3{

                     background-position: -42px 0;

                  }

               }

            }

            .statisics-item{
               
               text-align: center;

               flex:9;

               display: flex;

               .statisics-item_content{

                  padding: 15px 0;

                  flex:1.1;

                   p{

                     line-height:24px;

                     font-size:15px;

                     &:nth-child(2){

                        color:#00a0e4;

                     }

                  }



                  &.name{

                     flex:1.2;

                     text-align: left;

                  }

                  &.long{

                     flex:1.4

                  }

               }

              


            }

         }

      }

   

   .bd{

      flex:1;

      height:0;

      position: relative;

      overflow: hidden;

      display: flex;

      [class*='van-hairline']::after{border-color:#dddddd;z-index:10}

      .van-cell--clickable:active{background:#fff}

      .ripple-btn::before{

         background-image: radial-gradient(circle,#cccccc 10%,transparent 20%);

      }

      .van-collapse-item__content{

         padding: 0;

      } 



      .statisics-section .van-collapse-item .van-cell{

         display: block;

         padding: 0;

         .van-cell__right-icon{

            position: absolute;

            right:10px;

            top:14px;

         }

         &::after{

            left:0;

            right:0;

         }

      }

      .date-item{

            padding: 14px 16px 4px;

            overflow: hidden;

            &.bg{background:#f6f6f6};

            position: relative;

            z-index:10;

            .date-type{

               display: flex;

               justify-content: space-between;

               align-items: center;

               span{

                  color:#fff;
                  
                  font-size:13px;

                  padding: 2px 8px 3px;

                  border-radius:3px;

                  &.name{

                     color:#666666;

                     padding: 0;

                     font-size:14px;

                  }

                  &.arrow-icon{

                     color:#999999;

                     padding:4px 0 4px;

                     opacity: .6;

                  }

                  &.c-01{

                     background: #e6726d;

                  }

                  &.c-02{

                     background: #68a8a5;

                  }

                  &.c-03{

                     background: #67a883;

                  }

                  &.c-04{

                     background: #da9a8b;

                  }

                  &.c-05{

                     background: #f19497;

                  }         
                  
                  &.c-06{

                     background: #85a8ec;

                  }
                  

               }

            }

            .date-item-ctn{

               padding-top:10px;

               display: flex;

               flex-wrap: wrap;

               .date-item__el{

                  width: 50%;

                  line-height:24px;

                  margin-bottom:8px;

                  font-size:14px;

                  color:#666666;

                  &:nth-child(even){

                     text-align: right;

                  }

                  .value{

                     padding: 0 3px;

                     color:#ff7500;

                     opacity: .8;

                     &.danger{

                        color:#fc586b;

                     }

                     &.info{

                        color:#46a0fa;

                     }

                     &.primary{

                        color:#09c061;

                     }

                     &.warning{

                        color:#ff976a;

                     }

                  }

               }

            }

         }

   }

}
</style>
