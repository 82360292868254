<template>
   <div class="statistics-detail-wrapper">
      <div class="flex-box-column statistics-detail-cover">
         <div class="hd">
            <van-nav-bar left-arrow safe-area-inset-top :title="detailTitle" class="search" @click-left="back">
            </van-nav-bar>
            <div class="menu border_bottom z-index-10">
               <van-dropdown-menu class="z-index-10" :close-on-click-outside="false" ref="statistics_menu">
                        <van-dropdown-item ref="statistics_date" :title="dateTitle">
                            <van-cell center :title="item.text" @click="selectDate(item,index)" v-for="(item,index) in dateOptions" :title-class="{active:activeDateIndex == item.value}" :key="index"/>
                            <div class="date-interval">
                                <div class="date-interval-item" @click="calendarOpen">{{optionalStartTime || '开始时间'}}</div>
                                <div class="g">一</div>
                                <div class="date-interval-item" @click="calendarOpen">{{optionalEndTime || '结束时间'}}</div>
                                <button 
                                class="confirm date-interval-item" 
                                @click="selectDate({text:'自选时间',value:99})"
                                :class="{live:optionalStartTime && optionalEndTime}"
                                >确定</button>
                            </div>
                        </van-dropdown-item>
                        <van-dropdown-item ref="statistics_house" :title="houseSearchTitle" v-if="statisticsType == 'house'">
                           <div class="search-house-cover">
                              <van-search v-model="form.keyword" placeholder="请输入楼盘关键词" @keydown.native.enter="searchHouse" clearable />
                              <div class="search-button-cover">
                                 <van-button  block round color="linear-gradient(to right,#00a0e4,#0471ff)" @click="searchHouse">确定</van-button>
                              </div>
                           </div>
                        </van-dropdown-item>
                        <van-dropdown-item :title="managerTitle" ref="dropdownManage" v-if="statisticsType == 'staff'">
                           <van-cell
                           v-for="(item,index) in managerList"
                           :key="item.id"
                           :title="index == 0 ? item.text :item.text+'（'+(item.num || 0)+'）'" @click="expandPickerOpen(item)"
                           :title-class="{active:activeExpandGroup == item.id}"></van-cell>
                           <span v-if="managerList.length == 0" class="fky-tips">{{expandPickerInited ? '暂无数据' : '正在获取...'}}</span>
                        </van-dropdown-item>
                        <van-dropdown-item v-model="form.sort"  :options="sortOPtions" :title="sortTitle" @change="selectedSort" ref="selectedSort"/>
               </van-dropdown-menu>
         </div>
         </div>
         <div class="bd flex-box-column">
            <!-- 区间总数据 -->
            <div class="bg statisics-section statisics-title">
               <div class="statisics-row">
                  <div class="statisics-icon"></div>
                  <div class="statisics-item">
                     <div class="statisics-item_content name">
                        <p>{{statisticsType=='house' ? '楼盘名称' : '姓名'}}</p>
                     </div>  
                     <div class="statisics-item_content">
                        <p>报备量</p>
                        <p>{{statiscsAllData['baobeiNum'] || 0}}</p>
                     </div>
                     <div class="statisics-item_content">
                        <p>到访量</p>
                        <p>{{statiscsAllData['visit'] || 0}}</p>
                     </div>
                     <div class="statisics-item_content">
                        <p>成交量</p>
                        <p>{{statiscsAllData['makeBargain'] || 0}}</p>
                     </div>
                     <div class="statisics-item_content long">
                        <p>成交金额(万)</p>
                        <p>{{statiscsAllData['SUMMONEY'] || '0.00'}}</p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="statisics-section f-scroll-view" ref="expandScrollView">
               <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="100"
                    :error.sync="listError"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                    >
                  <template  v-for="(item,index) in statiscsDetailData">
                     <div class="statisics-row" v-if="statiscsDetailData" :key="index" @click="openHouseData(item)">
                        <div class="statisics-icon rank">
                           <span v-if="index<3" :class="'rank_'+(index+1)"></span>
                        </div>
                        <div class="statisics-item" :class="{' van-hairline--top':index>0}">
                           <div class="statisics-item_content name">
                              <p>{{item[statiscsItemNameKey]}}</p>
                           </div>  
                           <div class="statisics-item_content">
                              <p>{{ statisticsType == 'house' ? (item['baobeiNum'] || 0) : (item['baobeiNum'] || 0)}}</p>
                           </div>
                           <div class="statisics-item_content">
                              <p>{{statisticsType == 'house' ? (item['visit'] || 0) : (item['visit'] || 0)}}</p>
                           </div>
                           <div class="statisics-item_content">
                              <p>{{statisticsType == 'house' ? (item['makeBargain'] || 0) : (item['makeBargain'] || 0)}}</p>
                           </div>
                           <div class="statisics-item_content long">
                              <p>{{statisticsType == 'house' ? (item['SUMMONEY'] || '0.00') : (item['SUMMONEY'] || '0.00')}}</p>
                           </div>
                        </div>
                  </div>
                  </template>
               </van-list>
            </div>
         </div>
      </div>
      <!-- 区间弹窗 -->
      <van-calendar 
      v-model="calendarShow" 
      type="range"
      @confirm="selectDateInterval" 
      color="#00a0e4"
      :min-date="minDate" 
      :max-date="maxDate" 
      allow-same-day/>
       <!-- 选择对应拓展员工 -->
      <van-popup v-model="expandShowPicker" round position="bottom">
         <van-picker
            show-toolbar
            :columns="expandColumns"
            @cancel="expandShowPicker = false"
            @confirm="onExpandPickerConfirm"
            :title="expandPickerTitle"
         />
      </van-popup>
   </div>
</template>

<script>
/**
 * 统计页面
 */
import backMixin from "@/mixins/back";
import {getDateFky,dateChange,getDateText,isArray,cloneDeep} from "@/utils";
import sessionStorage from "@/utils/sessionStorage";
import api from "@/api";
export default {

   mixins:[backMixin],

   data(){

      return {

         //是否初始化完毕

         inited:false,

         //分组是否初始完毕

         expandPickerInited:false,








         dateOptions:[

            {text:'不限',value:0},

            { text: '本日', value: 1 },

            { text: '本周', value: 2 },

            { text:'上周', value:3},

            { text: '本月', value: 4 },

            {text:'上个月',value:5},

            {text:'本年',value:8}

         ],



         dateTitle:"选择日期",

         //选择的index

         activeDateIndex:0,

         managerTitle:"分组",

         //当前切换的分组index

         managerIndex:0,

         //标题

         sortOPtions:[

            {text:'按报备量',value:1},

            {text:'按到访量',value:2},

            {text:'按成交量',value:3}

         ],

         sortTitle:'排序',

         //自选起始时间

         optionalStartTime:'',

         //自选结束时间
         
         optionalEndTime:'',

         //分组列表
         
         managerList:[],

         //日历展示

         calendarShow:false,

         //日历时间区间

         minDate: new Date(2020,0,1),

         maxDate: new Date(),

         statiscsAllData:{},

         statiscsDetailData:[],

         //楼盘筛选头部

         houseSearchTitle:"楼盘筛选",





         //是否到达底部

         finished:false,

         //是否开始加载

         loading:false,

         //是否出错

         listError:false,

         //页码

         page:1,

         //一页显示的数量

         size:20,


         form:{
            
            sort:1,

            id:0
            
         },

         //展示列表人员
         
         expandShowPicker:false,

         //渠道人员选项列表

         expandColumns:[],

         //当前选中的渠道分组

         activeExpandGroup:0,

         //选中的分组名称

         expandPickerTitle:'',

         detailTitle:'报备数据详情',


         scrollTop:0

      }


   },

   computed:{



      //区间标题

      statisticsDateTitle(){

         return getDateText(this.activeDateIndex);

      },

      //统计类型

      statisticsType(){

         return this.$route.params.type;

      },

      //当前使用的名称key

      statiscsItemNameKey(){

         return 'name';

      }



   },


   methods:{

      //触底加载开始

      onLoad(){

         this.updateData();

      },

      //楼盘搜索

      searchHouse(){

         this.$refs.statistics_house.toggle(false);

         this.$nextTick(()=>{

            this.houseSearchTitle = this.form.keyword || '楼盘筛选';

            this.onSearch();

         })
      },

      //搜索专用方法

      onSearch(){

         //将页码归一

         this.page = 1;

         //开始搜索

          this.$toast.loading({

            message: '正在加载...',

            forbidClick: true,

            duration:0

         })


         this.updateData('search');

      },

      //请求数据

      updateData(type){

         this.loading=true;
          let formData = cloneDeep(this.form);
         if(formData.startDate && formData.endDate){
            formData.searchDate = [formData.startDate,formData.endDate];
         }
         let options = Object.assign(formData,{

            page:this.page,

            pageSize:this.size

         });

         api[this.$route.params.type == 'house' ? 'getHouseToReportStatistics' :'getExtensionToReportStatisticsa']({

            options,

            success:(res)=>{

               if(res.type == 200){
                  this.listError=false;
                  let last = this.$listLastCheck(res.data.details,this.size);
                  if(!last){
                     this.page++;
                  }

                  if(type == 'search'){

                     this.statiscsAllData = res.data?.all || {};

                     this.statiscsDetailData = res.data?.details || [];

                     this.$toast.clear();

                  }else{

                     this.statiscsAllData = res.data?.all || {};


                     if(res.data?.details && isArray(res.data.details)){

                        this.statiscsDetailData.push(...res.data.details);

                     }else{

                        this.finished=true;

                     }

                     
                  }

                  this.finished=last;

               }else{

                  this.$toast.fail('统计数据获取失败');

                  this.listError = true;

               }

            },

            error:(err)=>{

               console.log(err);

               this.listError = true;

               this.$toast.fail('统计数据获取异常');

            },

            complete: (res)=> {
               
               if(res?.code && res.code !=200){

                  this.listError = true;
                        
               }

               if(!this.inited){

                  this.inited = true;

               };

               this.loading=false;
               
            }


         })

      },

      //选择排序

      selectedSort(val){

         if(val == 1){

            this.sortTitle = '排序'

         }else{

            this.sortTitle= '';

         }

         this.$nextTick(()=>{

            this.onSearch();

         })

      },

      





       //选择分组

      expandPickerOpen(item){

         //选中对应的数据

         this.activeExpandGroup = item.id;

         let text = item.text.replace(/(\s+)|(-)/g,'');

         //判断是否存在下级元素

         if(item.children && item.children.length>0){

            //去除空格以及横杠

            this.expandPickerTitle = text;

            //提取数组

            this.expandColumns = item.children;

            this.expandShowPicker=true;

         }else{

            
            if(item.id == 0){

               //设置分组标题

               this.managerTitle =  '分组';

               //设置请求id

               this.$set(this.form,'id',item.id);

               this.$refs.dropdownManage.toggle(false);

               //请求数据

               this.$nextTick(()=>{

                  this.onSearch();

               })


            }else{

               this.$toast('该分组暂无数据');

            }

         }

       

      },

      //选择员工

      onExpandPickerConfirm(val){

         //设置选中的id

         this.$set(this.form,'id',val.id);

         //设置名称

         this.managerTitle = val.text;

         //关闭背景元素

         this.$refs.dropdownManage.toggle(false);

         //关闭picker

         this.expandShowPicker = false;

         //请求数据

         this.$nextTick(()=>{

            this.onSearch();

         })

      },
      

  

      //获取经纪人列表

      getExtensionSelectList(){

         api.getExtensionSelectList({

                success:(res)=>{

                  if(res.type == 200){


                     if(res.data && isArray(res.data)){

                        //进行处理

                        res.data.forEach(el=>{

                          isArray(el.children) && el.children.length != 0 && el.children.forEach(_el=>{

                              _el.text = _el.staffName || _el.text;

                           })

                        })

                        this.managerList = res.data;

                     }else{

                        this.managerList = [{text:'不限',value:0}]

                     }

                     

                  }else{

                     this.$toast({

                        message:'获取分组列表失败',

                        position:'bottom'

                     })

                  }

                },

                error:(err)=>{

                    console.log(err);

                },

                complete:()=>{

                   this.expandPickerInited=true

                }

            })

      },
      

      //选择时间

      selectDate(date){

            //自选

            if(date.value == 99 && (!this.optionalStartTime || !this.optionalEndTime)) return;

            this.dateTitle=date.text == '不限' ? '选择日期' : date.text;

            this.activeDateIndex = date.value;

            //获取时间区间

            if(date.value !=99){

               //拿到区间，转化为字符串

               this.setDate();

               //清除掉选择区间

               this.optionalStartTime = this.optionalEndTime = '';

               //保存

               sessionStorage.StorageSetter('datetype',date.value);

            }else{

               this.$set(this.form,'startDate',this.optionalStartTime);

               this.$set(this.form,'endDate',this.optionalEndTime);

            }

            this.$refs.statistics_date.toggle(false);
                
            this.$nextTick(()=>{

               this.onSearch();

            })

            
      },

      //设置时间

      setDate(){

         if(this.activeDateIndex == 0){

            this.startDate = '';

            this.endDate = '';

            this.$set(this.form,'startDate','');

            this.$set(this.form,'endDate','')

         }else{

            let inv = getDateFky(this.activeDateIndex);

            this.$set(this.form,'startDate',dateChange(inv.start,'date'));

            this.$set(this.form,'endDate',dateChange(inv.end,'date'))

         }

    

      },

      //选择日历区间

      selectDateInterval(val){

         //选择日历区间

         this.optionalStartTime = dateChange(val[0],'date');

         this.optionalEndTime = dateChange(val[1],'date');

         this.$nextTick(()=>{

            this.calendarShow = false;

         })

      },

      //打开日历

      calendarOpen(){

         this.calendarShow=true;

      },

      //选择弹出楼盘列表

      openHouseData(data){

         //如果不是拓展口页面不显示

         if(this.statisticsType != 'staff') return;

         let form = cloneDeep(this.form);

         form.staffName = data.name;

         //保存数据

         sessionStorage.StorageSetter('expand_house_data',form);

         //保存滚动高度

         this.scrollTop = this.$refs.expandScrollView.scrollTop;

         //跳转到对应列表

         this.$nextTick(()=>{

            this.$router.push({path:'/estate_development/statistics/house/detail/'+data.id});

         })

      },

      //请求对应拓展口的楼盘数据

      getExtensionHouseStatistics(id){

         this.$toast.loading({

            message:'正在加载...',

            duration:0,

            forbidClick:true
            
         });

         let options = Object.assign(this.form,{

            id
            
         })

         api.getExtensionHouseStatistics({

            options,


            success:(res)=>{

               if(res.type == 200){

                  if(res.data?.all && res.data?.details){

                     this.expandHouseData = res.data;

                  }


                  this.$nextTick(()=>{

                     this.houseDataShow=true;

                  })

               }else{

                  this.$toast.fail(res.msg || '获取数据失败，数据异常，请稍后重试');

               }

            },

            error:(err)=>{

               console.log(err);

               this.$toast.fail('获取数据失败，数据异常，请稍后重试');

            },

            complete:()=>{

               this.$toast.clear();

            }


         })

      },


      

      //初始化方法

      init(dateType){

         this.activeDateIndex = dateType;

         //设置标题

         
         let dateTitle = getDateText(this.activeDateIndex);

         this.dateTitle = dateTitle == '不限' ? '选择日期' : dateTitle;


         //设置时间

         this.setDate();

         //当为经纪人的时候请求经纪人列表

         if(this.statisticsType == 'staff'){

            //请求经纪人列表

            this.getExtensionSelectList();

         }

      }

   },


   activated(){

      //滚动距离
      
      if(this.scrollTop){

         this.$refs.expandScrollView.scrollTop=this.scrollTop;

      }

      //首先判断是否存在参数

      if(this.$route.query.dateType){

         this.init(this.$route.query.dateType);
      

      }else{

         //检查看是否有缓存，没有就显示 1

         let dateType = sessionStorage.StorageGetter('datetype');

         this.init(dateType || 1)

      }

   },

   created(){

     this.detailTitle =  this.statisticsType == 'house' ? '楼盘数据详情' : this.statisticsType == 'staff' ? '拓展口数据详情' : '报备数据详情'; 

   }



}
</script>

<style lang="less">
.statistics-detail-wrapper{

   height:100%;

   .search-house-cover{

      padding: 5px 10px;

      .van-cell{

         line-height:32px;

      }

   }

   .house-data-show-wrapper{

      background: #fff;

      width: 90%;

      position: absolute;

      left:50%;

      top:50%;

      transform: translate(-50%,-50%);

      height:80vh;

      overflow: hidden;

      border-radius:5px;

      .house-data-show-cover{

         width: 100%;

         height:100%;

      }

      .house-data-show-title{

         font-size:16px;

         padding: 10px 16px;

         line-height:normal;

         display: flex;
         
         justify-content: space-between;

         align-items: center;

         .house-data-show-close{

            padding-left:10px;

            &:active{

               color:#00a0e4;

            }

         }

         .van-icon{

            font-size:18px;

         }

      }

      .statisics-section .statisics-row .statisics-item .statisics-item_content{

         padding: 10px 0;

      }

      .statisics-section.statisics-title .statisics-row .statisics-item .statisics-item_content p{

         font-size:11px;

         &:nth-child(2){

            font-size:13px;

         }

      }

      .statisics-section .statisics-row .statisics-item .statisics-item_content p{

         font-size:13px;

      }

      .statisics-section .statisics-row .statisics-icon{

         padding: 10px 0;

      }

   }

   .search-button-cover{

      padding: 5px 10px 10px;

   }

   .search-dialog-input-cover{

      padding: 10px 0;

   }

   .statistics-detail-cover{

      height:100%;


   }

   .hd{

      position: relative;

      z-index:30;

         .date-interval{

               display: flex;

               padding:15px;

               align-items: center;

               .date-interval-item{

                   font-size:13px;

                   line-height:24px;

                   padding: 2px 8px 1px;

                   color:#999;

                   background:#f6f6f6;

                   &.confirm{

                    margin-left:40px;

                    padding: 2px 15px 1px;

                    background: #eee;

                    border-radius:3px;

                    border: 0;

                    &.live{

                        background: #00a0e4;

                        color:#fff;

                    }

                   }

               }

           }

   }

         .statisics-section{

         &.statisics-title{

            background: #f9f9f9;

            color:#999999;

            .statisics-row .statisics-item .statisics-item_content p{

               font-size:13px;

            }

         }

         .statisics-row{

            display: flex;

            padding-right:10px;

            .statisics-icon{

               flex:0.9;

               text-align: center;

               display: flex;

               padding:15px 0;

               justify-content: center;

               align-items: center;

               &.rank span{

                  background:url('../../assets/images/rank.png') no-repeat center center;

                  background-size:60px 18px;

                  display: block;

                  width: 18px;

                  height:18px;

                  position: relative;

                  top:-1px;

                  &.rank_1{

                     background-position: 0 0;

                  }

                  &.rank_2{

                     background-position: -21px 0;

                  }

                  &.rank_3{

                     background-position: -42px 0;

                  }

               }

            }

            .statisics-item{
               
               text-align: center;

               flex:9;

               display: flex;

               .statisics-item_content{

                  padding: 15px 0;

                  flex:1.1;

                   p{

                     line-height:24px;

                     font-size:15px;

                     &:nth-child(2){

                        color:#00a0e4;

                     }

                  }



                  &.name{

                     flex:1.2;

                     text-align: left;

                  }

                  &.long{

                     flex:1.4

                  }

               }

              


            }

         }

      }

   

   .bd{

      flex:1;

      height:0;

      position: relative;

      overflow: hidden;

      display: flex;

      [class*='van-hairline']::after{border-color:#dddddd;z-index:10}

      .van-cell--clickable:active{background:#fff}

      .ripple-btn::before{

         background-image: radial-gradient(circle,#cccccc 10%,transparent 20%);

      }

      .van-collapse-item__content{

         padding: 0;

      } 



      .statisics-section .van-collapse-item .van-cell{

         display: block;

         padding: 0;

         .van-cell__right-icon{

            position: absolute;

            right:10px;

            top:14px;

         }

         &::after{

            left:0;

            right:0;

         }

      }

      .date-item{

            padding: 14px 16px 4px;

            overflow: hidden;

            &.bg{background:#f6f6f6};

            position: relative;

            z-index:10;

            .date-type{

               display: flex;

               justify-content: space-between;

               align-items: center;

               span{

                  color:#fff;
                  
                  font-size:13px;

                  padding: 2px 8px 3px;

                  border-radius:3px;

                  &.name{

                     color:#666666;

                     padding: 0;

                     font-size:14px;

                  }

                  &.arrow-icon{

                     color:#999999;

                     padding:4px 0 4px;

                     opacity: .6;

                  }

                  &.c-01{

                     background: #e6726d;

                  }

                  &.c-02{

                     background: #68a8a5;

                  }

                  &.c-03{

                     background: #67a883;

                  }

                  &.c-04{

                     background: #da9a8b;

                  }

                  &.c-05{

                     background: #f19497;

                  }         
                  
                  &.c-06{

                     background: #85a8ec;

                  }
                  

               }

            }

            .date-item-ctn{

               padding-top:10px;

               display: flex;

               flex-wrap: wrap;

               .date-item__el{

                  width: 50%;

                  line-height:24px;

                  margin-bottom:8px;

                  font-size:14px;

                  color:#666666;

                  &:nth-child(even){

                     text-align: right;

                  }

                  .value{

                     padding: 0 3px;

                     color:#ff7500;

                     opacity: .8;

                     &.danger{

                        color:#fc586b;

                     }

                     &.info{

                        color:#46a0fa;

                     }

                     &.primary{

                        color:#09c061;

                     }

                     &.warning{

                        color:#ff976a;

                     }

                  }

               }

            }

         }

   }

}
</style>
